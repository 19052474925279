<template>
    <div class="date-picker-wrapper">
        <flat-pickr v-model="internalDate"
                    :value="internalDate"
                    :config="config"
                    class="custom-select__button"
                    :placeholder="placeholder"
                    name="date"/>

        <button
            type="button"
            v-if="internalDate"
            @click="clearDate"
            class="clear-button"
            aria-label="Clear date">
            <span class="icon-clear material-symbols-outlined">close</span>
        </button>
    </div>
</template>

<script setup>
    import {ref, watch} from "vue";
    import FlatPickr from "vue-flatpickr-component";

    const props = defineProps({
        modelValue: {
            type: [String, Array],
            default: null,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        placeholder: {
            type: String,
            default: "Select date",
        },
        value: {
            type: [String, Array],
            default: null
        },
    });

    const emit = defineEmits(["update:modelValue", "change"]);

    const internalDate = ref(props.modelValue || props.value);

    watch(
        () => props.modelValue,
        (newValue) => {
            internalDate.value = newValue;
        },
    );

    watch(internalDate, (newValue, oldValue) => {
        if (newValue === oldValue) return;

        if (!newValue) {
            emit("update:modelValue", "");
            emit("change", "");
        }

        if (props.config.mode === "range" && typeof newValue === "string") {
            const dates = newValue.split(" to ");
            if (dates.length === 2) {
                if (JSON.stringify(dates) === JSON.stringify(props.modelValue)) return;

                emit("update:modelValue", dates);
                emit("change", dates);
            }
            return;
        }

        if (newValue !== props.modelValue) {
            emit("update:modelValue", newValue);
            emit("change", newValue);
        }
    });

    const clearDate = () => {
        internalDate.value = null;

        emit("change", "");
    };
</script>
