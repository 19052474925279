<template>
    <button
        :type="type"
        :class="['btn', variantClass, sizeClass, outlineClass, roundedClass]">
        <template v-if="icon && iconPosition === 'left'">
            <span class="material-symbols-outlined icon-loading" v-if="loading">
                progress_activity
            </span>

            <span v-else
                  :class="[iconClass, { filled: props.filled }]"
                  class="material-symbols-outlined">
                {{ props.icon }}
            </span>
        </template>

        <slot></slot>

        <template v-if="icon && iconPosition === 'right'">
            <span class="material-symbols-outlined icon-loading" v-if="loading">
                progress_activity
            </span>

            <span v-else class="material-symbols-outlined">{{ props.icon }}</span>
        </template>
    </button>
</template>

<script setup>
    import {computed} from "vue";

    const props = defineProps({
        icon: {
            type: String,
            default: null,
        },
        iconPosition: {
            type: String,
            default: "left",
        },
        iconClass: [String, Object],
        filled: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "md",
        },
        type: {
            type: String,
            default: "button",
        },
        outline: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: "red",
        },
        roundedFull: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    });

    const variantClass = computed(() => {
        switch (props.variant) {
            case "gray":
                return "variant-gray";
            case "success":
                return "variant-success";
            case "warning":
                return "variant-warning";
            case "info":
                return "variant-info";
            default:
                return "variant-red";
        }
    });

    const outlineClass = computed(() => (props.outline ? "btn-outline" : ""));

    const sizeClass = computed(() => `size-${props.size}`);

    const roundedClass = computed(() => (props.roundedFull ? "rounded-full" : ""));
</script>
